<template>
  <div class="scroll-container">
    <ListPreferred v-if="!listId || listId == 'preferred'" />
    <ListDetail v-else :listId="listId" :showSectors="false" />
  </div>
</template>

<script>
import ListDetail from "@/components/lists/ListDetail.vue";
import ListPreferred from "@/components/lists/ListPreferred.vue";
import list from "~/mixins/list";
import categoryMixins from "~/mixins/category";

export default {
  name: "List",
  mixins: [list, categoryMixins],
  components: {
    ListDetail,
    ListPreferred
  },
  data() {
    return {
      listId: null
    };
  },
  created() {
    this.listId = this.$route.params.listId;
  }
};
</script>
